@import "reset.css";

h1 {
    font-size: 7vh;
    text-align: center;
    margin-bottom: 2vh;
    font-family: "Oxygen";
}

h2 {
    font-size: 4vh;
    text-align: center;
    margin-bottom: 2vh;
}

.contentBox3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vh;
    justify-content: space-evenly;
    height: 85%;
}

.center_h2_text {
    text-align: center;
}

.contentBox3  img {
    align-self: center;
    width: auto;
    height: 40vh;
    
    margin-top: 3vh
}

.button {
    width: 500px;
}

.toplevel {
    background-color: var(--main-background-color);
    width: 100%;
    height: 100%;
}

body {
    height: 100%;
    width: 100%;
}

html {
    height: 100%;
    width: 100%;
}

