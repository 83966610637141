:root {
    --header-color: #d2e5b0;
    --main-background-color: #EEEEEE;
    --secondary-background-color: #d4e7dc;
    --button-color-text: #6b6b6b;
    --button-color-border: #b8b8b8;
    --button-color: #eeeeee;

    --active-tab-color: #bfda8e;
    --done-tab-color: #95c93d;
    --done-tab-color-hover: #a4cc5e;
    --button-color-text: #070707;
    --disabled-tab-color: #e0edc8;

    --section-color: white;
}