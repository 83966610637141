@import "../styling/reset.css";
@import "../styling/colors.css";


.section {
    display: flex;
    /*height: 100%;*/
    background-color: var(--section-color);
    -webkit-box-shadow: 3px 0px 3px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 3px 0px 3px 0px rgba(0,0,0,0.25);
    box-shadow: 3px 0px 3px 0px rgba(0,0,0,0.25), -3px 0px 3px 0px rgba(0,0,0,0.25);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* this is the flex container that will take the rest of the height */
.content_wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 0px; /* IMPORTANT: you need this for non-chrome browsers */
  }
  
  /* the container for our overflowed content */
  .overflow_container {
    flex: 1;
    overflow: auto;
  }
  
  /* the overflow content itself */
  .overflow_content {
    height: 2000px;
    color: black;
    background-color: #ddd;
    padding: 20px;
  }