@import "../styling/reset.css";
@import "../styling/colors.css";

.h1 {
    font-size: 5vw;
    text-align: center;
    margin-bottom: 2vh;
    font-family: "Oxygen";
}

.h2 {
    font-size: 3vw;
    text-align: center;
    margin-bottom: 1.5vh;
    font-family: "Oxygen";
}

.img {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    width: 40%;
    height: auto;
}


.button {
    width: 500px;
}

@media screen and ( min-width: 1000px) {
    .h1 {
        font-size: 50px; 
    }

    .h2 {
        font-size: 30px; 
    }
}

@media screen and ( min-width: 1600px) {
    .h1 {
        font-size: 3vw; 
    }

    .h2 {
        font-size: 1.7vw; 
    }
}