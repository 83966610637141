@import "reset.css";
@import "colors.css";

.custom_button {
    cursor: pointer;
    background-color: var(--button-color);
    border: none;
    color:  var(--button-color-text);
    padding: 10px 20px;
    margin: 4px 2px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: calc(10px + 0.5vw);
    -webkit-transition-duration: 0.2s; /* Safari */
    width: 10vw;
    min-width: 85px;
    border-width: 2px;
    border-color: var(--button-color-border);
    border-style: solid;
    border-radius: 5px;
    -webkit-box-shadow: 0px 6px 3px -3px rgba(189,189,189,1);
    -moz-box-shadow: 0px 6px 3px -3px rgba(189,189,189,1);
    box-shadow: 0px 6px 3px -3px rgba(189,189,189,1);
    box-sizing: border-box;
    font-weight: bold;
    margin: 2vh;
    min-height: 50px;
}

.custom_button:hover {
    background-color: var(--disabled-tab-color);
    border-color: var(--active-tab-color);
  }