@import "reset.css";

.box_outer {
    background: #F1F1F166;
    padding: 1%;
    padding-top: 0;
    box-sizing: border-box;
    border-color: #D4E7DC;
    border-width: 3px;
    border-radius: 7px;
    border-style: solid;
    width: 60%;
    margin: 0vh 1vh;
}



.titlesection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 3vw;
    
    font-family: "Oxygen"
}

.box_children_list {
    display: flex;
    flex-direction: column;
    
}
