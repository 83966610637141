@import "../styling/reset.css";
@import "../styling/colors.css";

.basis {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
}

.dim {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 154px;
}

.maxdim {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
}

.h1 {
    align-self: center;
    font-size: 2.6vw;
    margin-bottom: 1.5vh;
}


.hidden {
  display:none; 
}

.helph1 {
  align-self: center;
    font-size: 2.6vw;
    margin-bottom: 1.5vh;
    margin-top: 3vh;
}

.h2 {
    align-self: flex-start;
    font-size: 22px;
    text-align: start;
    margin-bottom: 1vh;
    margin-top: 1.5vh;
    font-family: "Oxygen";
}

.h3 {
    margin-top: 12px;
    margin-bottom: 5px;
    font-size: 1.5vw;
    font-family: "Oxygen";
    text-align: start;
}

.name_label {
    margin-top: 7px;
    margin-bottom: 5px;
    font-size: 1.5vw;
    font-family: "Oxygen";
    text-align: start;
}

.name_input {
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    padding: 7px;
    align-items: center;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(150, 150, 150);
    border-image: initial;
    border-radius: 5px;
    font-size: 1.5vw;
    width: 30%;
    margin-bottom: 2vh;
    margin-left: 1vw;
    height: 4.2vh;
    box-sizing: border-box;
}

.title {
  align-self: center;
    font-size: 2.6vw;
    margin-bottom: 2vh;
    margin-top: 3vh;
}


.psmall, .p, .ul, .a, .helpp, .helpa{
    align-self: flex-start;
    margin-bottom: 1vh;
    font-size: 1.5vw;
    font-family: "Oxygen";
    text-align: start;
}

.psmall {
  font-size: 1vw;
}

.helpp, .helpa {
  line-height: 1.5vw;
}

.a {
    margin-top: 5px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.ul {
    margin-left: 5%;
    list-style-type: disc;
}

.help_img {
  max-width: 100%;
}


.image {
    max-width: 100%;
    width: auto;
    height: auto;
}

.loading_shadow {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.479);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.table {
  font-family: "Oxygen";
  width: 96%;
  margin: 2%;
}

.tr {
  background-color: #fafafa;
}

.tr:nth-child(even) {
  background-color: var(--button-color);
}

.th {
  text-align: left;
  font-weight: bold;
  background-color: var(--header-color);
  padding: 5px;
  font-size: 1.7vw;
}

.td {
  padding: 5px;
  font-size: 1.5vw;
}


.sk_folding_cube {
    margin: 20px auto;
    width: 60px;
    height: 60px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
  }
  
  .sk_folding_cube .sk_cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); 
  }
  .sk_folding_cube .sk_cube:before {
      z-index: 30;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--done-tab-color-hover);
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
            animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  .sk_folding_cube .sk_cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg);
  }
  .sk_folding_cube .sk_cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg);
  }
  .sk_folding_cube .sk_cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg);
  }
  .sk_folding_cube .sk_cube2:before {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .sk_folding_cube .sk_cube3:before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; 
  }
  .sk_folding_cube .sk_cube4:before {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }
  @-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    } 
  }
  
  @keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    }
  }

@media screen and ( min-width: 1000px) {
    .h3 {
        font-size: 15px;
    }

    .name_label {
        font-size: 15px; 
    }

    .name_input {
        font-size: 15px;
    }

    .psmall, .p, .ul, .helpp, .th, .td, .helpa{
        font-size: 1rem; 
    }

    .psmall {
      font-size: 0.6rem;
    }
}


@media screen and ( min-width: 1100px) {

    .h1 {
        font-size: 32px; 
    }

    .helph1 {
      font-size: 32px; 
    }

    .title {
      font-size: 40px;
    }

    
}