@import "../styling/reset.css";
@import "../styling/colors.css";


.dropdown {
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    padding: 0.5vh;
    align-items: center;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(150, 150, 150);
    border-image: initial;
    height: 4.2vh;
    border-radius: 5px;
    font-size: 1.5vw;
    min-height: 30px;
    max-height: 40px;
    
    padding-left: 7px;
    padding-right: 35px;
}

.outerBox {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-bottom: 7px;
    min-width: 150px;
    min-height: 30px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
}

.outerBox2 {
    box-sizing: border-box;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    margin-bottom: 7px;
    min-width: 100px;
    min-height: 30px;
    
    padding-left: 4px;
    padding-right: 4px;
}

.outerBox2 .dropdown {
    width: inherit;
    width: 100%;
    text-align: start;
}

.outerBox2 select {
    width: 100%; 
    text-align-last:left; 
}

.outerBox2 .upperlabel {
    margin-bottom: 5px;
    font-family: "Oxygen";
    margin-left: -1vw;
    font-size: 1.5vw;
}

.outerBox .innerlabel {
    position: absolute;
    margin-left: 10px;
    pointer-events: none;
    font-family: "Oxygen";
    z-index: 1;
    font-size: 1.5vw;
}

.outerBox label::after {
    content: ":";
}

input {
    border: 0;

    background-color: #D5D5D5;
}

select {
    width: 100%; text-align-last:right; 
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
    background-position-x: calc(100% - 10px);
}

.select_hotfix {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: white;
    margin: 0.5vh;
    border-radius: 5px;
    pointer-events: none;
    margin-left: 20px;
    margin-right: 32px;
}

.not_hotfix {
    display: none;
}

.hotfix_text {
    position: absolute;
    right: 0;
    margin-right: 32px;
    pointer-events: none;
    font-family: "Oxygen";
    z-index: 1;
    font-size: 1.5vw;
}

select:hover {
   border-color: #888;
}

@media screen and (min-width: 900px) {
    .outerBox {
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .outerBox2 {
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .select_hotfix {
        margin-right: calc(1vw + 32px);
        margin-left: calc(1vw + 3px);
    }
    .hotfix_text {
        margin-right: calc(1vw + 32px);
    }
}

@media screen and (min-width: 1000px) {
    .outerBox2 .upperlabel {
        font-size: 15px; 
    }

    .outerBox .innerlabel {
        font-size: 15px; 
    }

    .dropdown {
        font-size: 15px; 
    }

    .hotfix_text {
        font-size: 15px; 
    }
    
}