@import "reset.css";
@import "colors.css";

body {
    height: 100%;
    width: 100%;
    
    background-color: var(--main-background-color);
}

html {
    height: 100%;
    width: 100%;
}

.root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.top_level {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
}

.content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
}

b {
    font-weight: bold;
}