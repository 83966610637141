@import "../styling/reset.css";
@import "../styling/colors.css";


.button {
    margin-left: 2px;
    border-radius: 100%; 
    height: 20px;
    width: 20px;
    background-color: var(--active-tab-color);
    cursor: pointer;
    font-family: "Oxygen";
    font-size: 10px;
    text-align: center;
    border-style: none;
}

.button:hover {
    background-color: var(--done-tab-color);
}