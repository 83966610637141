@import "../styling/reset.css";
@import "../styling/colors.css";

footer {
    display: flex; 
    justify-content: space-between;
    width: 100%;
    height: 22px;
    min-height: 22px;
    max-height: 22px;
    background-color: var(--header-color);
}

footer p {
    margin-left: 5px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Oxygen";
    font-size: 12px;
    color: gray;
}

footer a {
    margin-left: 5px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Oxygen";
    font-size: 12px;
    color: gray;
    text-decoration: underline;
    cursor: pointer;
}