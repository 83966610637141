@import "../styling/reset.css";
@import "../styling/colors.css";

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 3px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: var(--header-color);
    box-sizing: border-box;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
    z-index: 1;
    min-height: 45px;
    max-height: 45px;
}

.image {
    width: auto;
    height: 35px;
    cursor: pointer;
}

svg {
    width: auto;
    height: 40px;
}


.svg_text {
    pointer-events: none;
}

.svg_disabled {
    fill:  var(--disabled-tab-color);
    pointer-events: none;
}


.svg_active {
    fill: var(--active-tab-color);
    pointer-events: none;
}

.svg_done {
    fill: var(--done-tab-color);
    cursor: pointer;
}
.svg_done:hover {
    fill: var(--done-tab-color-hover);
}
